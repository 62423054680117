import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { AccountCircle, MoreVertOutlined } from "@material-ui/icons";
import * as React from "react";
import { useNavigate } from "react-router";
import "./index.scss";
import { changeOnlineActivity, logout } from "../../redux/users/userSlice";
import MyProSidebar from "../../pages/admin/components/sidebar";
import { userServices } from "../../services/userServices";

const pages = [
  "Platform",
  "Features",
  "Tools",
  "FAQ",
  "About us",
  // "Team",
  // "advisers",
  "careers",
  // "locations",
  "Contact us",
];

const Header = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [navbarClass, setNavbarClass] = React.useState("navbar");
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  // const [settings, setSettings] = React.useState([
  //   "Trade",
  //   "Wallet",
  //   "Account",
  //   // "Team",
  //   // "Advisers",
  //   "Locations",
  //   "Contact Us",
  //   "Logout",
  // ]);

  const settings = [
    "Trade",
    "Wallet",
    "Account",
    // "Team",
    // "Advisers",
    // "Locations",
    "Contact Us",
    "Logout",
    user?.role === "admin"
      ? "Admin"
      : user?.role === "crm_manager"
      ? "CRM"
      : user?.role === "head_of_conversion"
      ? "Head of conversion"
      : user?.role === "retention"
      ? "Retention"
      : user?.role === "conversion" && "Conversion",
  ];
  const dispatch = useDispatch();

  const checkScrollDown = () => {
    if (window.scrollY > 100) {
      setNavbarClass("navbar-on-scroll");
    } else {
      setNavbarClass("navbar");
    }
  };

  // React.useEffect(() => {
  //   if (user?.role?.toLowerCase() === "retention") {
  //     setSettings([...settings, "Retention"]);
  //   } else if (user?.role?.toLowerCase() === "admin" ) {
  //     setSettings([...settings, "Admin"]);
  //   } else if (user?.role?.toLowerCase() === "conversion") {
  //     setSettings([...settings, "Conversion"]);
  //   }
  // }, [user?.role]);

  const uniqueSettings = [...new Set(settings.filter((el) => el))];

  React.useEffect(() => {
    window.addEventListener("scroll", checkScrollDown);
  }, [window.scrollY]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = async (e) => {
    if (e.target.outerText.toLowerCase() === "platform") {
      navigate("/platform");
    } else if (e.target.outerText.toLowerCase() === "features") {
      navigate("/features");
    } else if (e.target.outerText.toLowerCase() === "tools") {
      navigate("/tools");
    } else if (e.target.outerText.toLowerCase() === "faq") {
      navigate("/faq");
    } else if (e.target.outerText.toLowerCase() === "about us") {
      navigate("/about-us");
    }
    // else if (e.target.outerText.toLowerCase() === "team") {
    //   navigate("/our-team");
    // }
    // else if (e.target.outerText.toLowerCase() === "advisers") {
    //   navigate("/our-advisers");
    // }
    else if (e.target.outerText.toLowerCase() === "careers") {
      navigate("/we-are-hiring");
    } else if (e.target.outerText.toLowerCase() === "trade") {
      navigate("/trade");
    } else if (e.target.outerText.toLowerCase() === "account") {
      navigate("/personal-details");
    } else if (e.target.outerText.toLowerCase() === "wallet") {
      navigate("/wallet");
    } else if (e.target.outerText.toLowerCase() === "locations") {
      navigate("/locations");
    } else if (e.target.outerText.toLowerCase() === "admin") {
      navigate("/admin/users");
    } else if (e.target.outerText.toLowerCase() === "crm") {
      navigate("/admin/users");
    } else if (e.target.outerText.toLowerCase() === "head of conversion") {
      navigate("/admin/users");
    } else if (e.target.outerText.toLowerCase() === "conversion") {
      navigate("/admin/users");
    } else if (e.target.outerText.toLowerCase() === "retention") {
      navigate("/admin/users");
    } else if (e.target.outerText.toLowerCase() === "affiliate") {
      navigate("/admin/affiliate");
    } else if (e.target.outerText.toLowerCase() === "logout") {
      await userServices.changeOnlineActivity(user?.id, false);
      dispatch(logout());
      localStorage.clear();
      navigate("/");
    } else {
      navigate("/contact-us");
    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (user?.role === "conversion" ||
    user?.role === "retention" ||
    user?.role === "admin" ||
    user?.role === "crm_manager" ||
    user?.role === "head_of_conversion") &&
    window.location.href.includes("/admin") ? (
    <MyProSidebar />
  ) : (
    <AppBar
      className={navbarClass}
      position="static"
      color="transparent"
      style={{
        position: "fixed",
        top: "1%",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters style={{ justifyContent: "space-between" }}>
          {user?.email ? (
            <>
              <Typography
                className="header-image-container"
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2 }}
                style={{ marginLeft: "1%", marginTop: "0.5%" }}
              >
                <img
                  height={180}
                  width={250}
                  alt="vrc-markets-logo"
                  src="vrc-markets-logo.png"
                  className="header-logo"
                />
              </Typography>
              <Box>
                <IconButton onClick={handleOpenUserMenu}>
                  <Tooltip title={user?.firstName + " " + user?.lastName}>
                    <AccountCircle
                      alt={user?.firstName + " " + user?.lastName}
                      style={{
                        // display: "flex",
                        position: "fixed",
                        right: "4%",
                        color: "white",
                        fontSize: "3.5rem",
                      }}
                    />
                  </Tooltip>
                </IconButton>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  style={{
                    top: window.innerWidth <= 667 ? "5%" : "6%",
                    left: window.innerWidth <= 667 ? "55%" : "85%",
                  }}
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {uniqueSettings?.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Button
                        style={{ color: "black", fontWeight: "bold" }}
                        textalign="center"
                        onClick={(e) => handleCloseNavMenu(e)}
                      >
                        {setting}
                      </Button>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </>
          ) : (
            <>
              <Typography
                className="header-image-container"
                variant="h6"
                noWrap
                component="div"
                onClick={() => navigate("/")}
                sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                style={{ marginLeft: "1%", marginTop: "0.5%" }}
              >
                <img
                  height={180}
                  width={250}
                  alt="vrc-markets-logo"
                  src="vrc-markets-logo.png"
                  className="header-logo"
                />
              </Typography>

              <Box
                style={{ color: "white", fontWeight: "bold" }}
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", md: "none" },
                  justifyContent: "flex-end",
                }}
              >
                {window.innerWidth < 677 && (
                  <IconButton
                    style={{ color: "white", fontWeight: "bold" }}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                  >
                    <MoreVertOutlined />
                  </IconButton>
                )}
                <Menu
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      style={{ color: "white", fontWeight: "bold" }}
                      key={page}
                      onClick={handleCloseNavMenu}
                    >
                      <Typography
                        style={{ color: "black", fontWeight: "bold" }}
                      >
                        {page}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "flex-end",
                }}
              >
                {pages.map((page) => (
                  <Button
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "19px",
                    }}
                    key={page}
                    onClick={(e) => handleCloseNavMenu(e)}
                    sx={{
                      my: 2,
                      color: "white",
                      fontWeight: "bold",
                      display: "block",
                    }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Button
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    backgroundColor: "#648eb3",
                    fontSize: "19px",
                    margin: "0 10px",
                  }}
                  sx={{
                    my: 2,
                    color: "white",
                    fontWeight: "bold",
                    display: "block",
                  }}
                  onClick={() => navigate("/login")}
                >
                  Login
                </Button>
                <Button
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "19px",
                    backgroundColor: "#648eb3",
                  }}
                  sx={{
                    my: 2,
                    color: "white",
                    fontWeight: "bold",
                    display: "block",
                  }}
                  // onClick={() => navigate("/sign-up")}
                >
                  Sign up
                </Button>
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
