import React, { useEffect } from "react";
import Header from "./components/header";
import SignUpPage from "./pages/signUp";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import PlatformPage from "./pages/platformPage";
import FeaturesPage from "./pages/featuresPage";
import ToolsPage from "./pages/toolsPage";
import FaqPage from "./pages/faqPage";
import AboutUsPage from "./pages/aboutUsPage";
import ContactUsPage from "./pages/contactUsPage";
import LoginPage from "./pages/login";
import TermsAndConditionsPage from "./pages/termsAndConditionsPage";
import PrivacyPolicyPage from "./pages/privacyPolicyPage";
import AmlPolicyPage from "./pages/amlPolicyPage";
import DepositPolicyPage from "./pages/depositPolicyPage";
import RefundPolicyPage from "./pages/refundPolicyPage";
import MobilePrivacyPage from "./pages/mobilePrivacyPage";
import SecurityPolicyPage from "./pages/securityPolicyPage";
import RiskDisclaimerPage from "./pages/riskDesclaimerPage";
import KycPolicyPage from "./pages/kycPolicyPage";
import SecurityPage from "./pages/securityPage";
import LandingPage from "./pages/landingPage";
import ForgottenPasswordPage from "./pages/forgotPasswordPage";
import ForgottenPasswordPageReset from "./pages/forgotPasswordPageReset";
import TradePage from "./pages/tradePage";
import WalletPage from "./pages/walletPage";
import HistoryPage from "./pages/historyPage";
import AccountTypesPage from "./pages/accountTypesPage";
import DepositPage from "./pages/depositPage";
import PersonalDetails from "./pages/personalDetailsPage";
import SecurityTradePage from "./pages/securityTradePage";
import AccountDocuments from "./pages/accountDocuments";
import AdminPage from "./pages/admin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TransactionsPage from "./pages/TransactionsPage";
import WithDrawPage from "./pages/withDrawPage";
import { userServices } from "./services/userServices";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "./pages/admin/components/dashboard/index";
import Users from "./pages/admin/components/users/users";
import AdminAffiliates from "./pages/admin/components/affiliate";
import { CancelledDeposit } from "./pages/depositResponse/CancelledDeposit";
import { SuccessfulDeposit } from "./pages/depositResponse/SuccessfulDeposit";
import {
  changeOnlineActivity,
  loginUserAuto,
  logout,
} from "./redux/users/userSlice";
import FirmPage from "./pages/firmPage";
import BoardOfAdvisersPage from "./pages/boardOfAdvisers";
import WeAreHiringPage from "./pages/weAreHiring";
import OurLocationsPage from "./pages/ourLocationsPage";
import AuthVerify from "./helpers/authVerify";
import RankingBoard from "./pages/admin/components/board";
import { ColorModeContext, useMode } from "./theme";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useIdleTimer } from "react-idle-timer";
import { SkeletonTheme } from "react-loading-skeleton";

function App() {
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const onIdle = async () => {
    toast.error(`Your session has expired. Please login again!`, {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      autoClose: false,
      pauseOnHover: true,
      toastId: 3,
    });
    if (!!user) {
      await userServices.changeOnlineActivity(user?.id, false).then(() => {
        dispatch(logout());
      });
    }
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 3600000,
    throttle: 1000,
  });

  const [autoLoggedIn, setAutoLoggedIn] = React.useState(false);
  const dispatch = useDispatch();

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  let value = params.email;

  useEffect(() => {
    if (user?.underInvestigation) {
      toast.error(`Your account has been put on under investigation mode!`, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        autoClose: false,
        pauseOnHover: true,
        toastId: 3,
      });
      dispatch(logout());
    }

    if (user?.loginForbidden) {
      toast.error(
        `
        You cannot login! Please contact your financial adviser for more information!`,
        {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          autoClose: false,
          pauseOnHover: true,
          toastId: 3,
        }
      );
      dispatch(logout());
    }
  }, [dispatch, user?.underInvestigation, user?.loginForbidden]);

  useEffect(() => {
    if (value) {
      userServices.autoLoginUser(value).then((user) => {
        localStorage.setItem("accessToken", user.accessToken);
        dispatch(loginUserAuto(user.foundUser.email));
        setAutoLoggedIn(true);
      });
    }
  }, []);
  const [theme, colorMode] = useMode();

  return (
    <BrowserRouter>
      <>
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="App">
                  <Header />
                  <main>
                    <Routes>
                      <Route path="/" exact element={<LandingPage />} />
                      <Route
                        path="/platform"
                        exact
                        element={<PlatformPage />}
                      />
                      <Route
                        path="/features"
                        exact
                        element={<FeaturesPage />}
                      />
                      <Route path="/tools" exact element={<ToolsPage />} />
                      <Route path="/faq" exact element={<FaqPage />} />
                      <Route path="/about-us" exact element={<AboutUsPage />} />
                      {/* <Route path="/our-team" exact element={<FirmPage />} /> */}
                      {/* <Route
                      path="/locations"
                      exact
                      element={<OurLocationsPage />}
                    /> */}
                      {/* <Route path="/our-advisers" exact element={<BoardOfAdvisersPage />} /> */}
                      <Route
                        path="/we-are-hiring"
                        exact
                        element={<WeAreHiringPage />}
                      />
                      <Route
                        path="/contact-us"
                        exact
                        element={<ContactUsPage />}
                      />
                      <Route path="/login" exact element={<LoginPage />} />
                      <Route
                        path="/sign-up-secure"
                        exact
                        element={<SignUpPage />}
                      />
                      <Route
                        path="/forgotten-password"
                        exact
                        element={<ForgottenPasswordPage />}
                      />
                      <Route
                        path="/forgot-password-reset-flow"
                        exact
                        element={<ForgottenPasswordPageReset />}
                      />
                      {user && user?.role === "admin" ? (
                        <>
                          <Route path="/admin" exact element={<AdminPage />} />
                          <Route
                            path="/admin/dashboard"
                            exact
                            element={<Dashboard />}
                          />
                          <Route
                            path="/admin/users"
                            exact
                            element={<Users />}
                          />
                          <Route
                            path="/admin/affiliate"
                            exact
                            element={<AdminAffiliates />}
                          />
                          {/* <Route
                          path="/admin/board"
                          exact
                          element={<RankingBoard />}
                        /> */}
                        </>
                      ) : (
                        false
                      )}
                      {user && user?.role === "crm_manager" ? (
                        <>
                          <Route path="/admin" exact element={<AdminPage />} />

                          <Route
                            path="/admin/users"
                            exact
                            element={<Users />}
                          />
                          <Route
                            path="/admin/affiliate"
                            exact
                            element={<AdminAffiliates />}
                          />
                        </>
                      ) : (
                        false
                      )}
                      {user && user?.role === "head_of_conversion" ? (
                        <>
                          <Route path="/admin" exact element={<AdminPage />} />

                          <Route
                            path="/admin/users"
                            exact
                            element={<Users />}
                          />
                        </>
                      ) : (
                        false
                      )}
                      {user && user?.role === "affiliate" ? (
                        <Route
                          path="/admin/affiliate"
                          exact
                          element={<AdminAffiliates />}
                        />
                      ) : (
                        false
                      )}
                      {user && user?.role === "retention" ? (
                        <>
                          <Route
                            path="/admin/users"
                            exact
                            element={<Users />}
                          />
                          <Route
                            path="/admin/dashboard"
                            exact
                            element={<Dashboard />}
                          />
                          {/* <Route
                          path="/admin/board"
                          exact
                          element={<RankingBoard />}
                        /> */}
                        </>
                      ) : (
                        false
                      )}
                      {user && user?.role === "conversion" ? (
                        <>
                          <Route
                            path="/admin/users"
                            exact
                            element={<Users />}
                          />
                        </>
                      ) : (
                        false
                      )}
                      <Route
                        path="/privacy"
                        exact
                        element={<PrivacyPolicyPage />}
                      />
                      <Route
                        path="/terms"
                        exact
                        element={<TermsAndConditionsPage />}
                      />
                      <Route path="/aml" exact element={<AmlPolicyPage />} />
                      <Route
                        path="/deposit-policy"
                        exact
                        element={<DepositPolicyPage />}
                      />
                      <Route
                        path="/refund-policy"
                        exact
                        element={<RefundPolicyPage />}
                      />
                      <Route
                        path="/mobile-privacy"
                        exact
                        element={<MobilePrivacyPage />}
                      />
                      <Route
                        path="/security-policy"
                        exact
                        element={<SecurityPolicyPage />}
                      />
                      <Route
                        path="/risk-disclaimer"
                        exact
                        element={<RiskDisclaimerPage />}
                      />
                      <Route path="/kyc" exact element={<KycPolicyPage />} />
                      <Route
                        path="/security"
                        exact
                        element={<SecurityPage />}
                      />
                      {user && user?.email ? (
                        <Route path="/wallet" exact element={<WalletPage />} />
                      ) : (
                        <Route
                          path="*"
                          element={<Navigate to="/login" replace />}
                        />
                      )}
                      {user && user?.email ? (
                        <Route path="/trade" exact element={<TradePage />} />
                      ) : (
                        <Route
                          path="*"
                          element={<Navigate to="/login" replace />}
                        />
                      )}

                      {/* {user && user?.email ? (
              <Route path="/payment-page" exact element={<PaymentPage />} />
            ) : (
              <Route path="*" element={<Navigate to="/login" replace />} />
            )} */}
                      {user && user?.email ? (
                        <Route
                          path="/history"
                          exact
                          element={<HistoryPage />}
                        />
                      ) : (
                        <Route
                          path="*"
                          element={<Navigate to="/login" replace />}
                        />
                      )}
                      {user && user?.email ? (
                        <Route
                          path="/account-types"
                          exact
                          element={<AccountTypesPage />}
                        />
                      ) : (
                        <Route
                          path="*"
                          element={<Navigate to="/login" replace />}
                        />
                      )}
                      {user && user?.email ? (
                        <Route
                          path="/deposit"
                          exact
                          element={<DepositPage />}
                        />
                      ) : (
                        <Route
                          path="*"
                          element={<Navigate to="/login" replace />}
                        />
                      )}
                      {user && user?.email ? (
                        <Route
                          path="/canceled-deposit"
                          exact
                          element={<CancelledDeposit />}
                        />
                      ) : (
                        <Route
                          path="*"
                          element={<Navigate to="/login" replace />}
                        />
                      )}
                      {user && user?.email ? (
                        <Route
                          path="/successful-deposit"
                          exact
                          element={<SuccessfulDeposit />}
                        />
                      ) : (
                        <Route
                          path="*"
                          element={<Navigate to="/login" replace />}
                        />
                      )}
                      {user && user?.email ? (
                        <Route
                          path="/personal-details"
                          exact
                          element={<PersonalDetails />}
                        />
                      ) : (
                        <Route
                          path="*"
                          element={<Navigate to="/login" replace />}
                        />
                      )}

                      {user && user?.email ? (
                        <Route
                          path="/withdraw"
                          exact
                          element={<WithDrawPage />}
                        />
                      ) : (
                        <Route
                          path="*"
                          element={<Navigate to="/login" replace />}
                        />
                      )}

                      {user && user?.email ? (
                        <Route
                          path="/transactions"
                          exact
                          element={<TransactionsPage />}
                        />
                      ) : (
                        <Route
                          path="*"
                          element={<Navigate to="/login" replace />}
                        />
                      )}

                      {user && user?.email ? (
                        <Route
                          path="/account-documents"
                          exact
                          element={<AccountDocuments />}
                        />
                      ) : (
                        <Route
                          path="*"
                          element={<Navigate to="/login" replace />}
                        />
                      )}
                      {user &&
                        user?.email &&
                        !!autoLoggedIn &&
                        window.location.replace("/deposit")}

                      {user && user?.email ? (
                        <Route
                          path="/security-trading"
                          exact
                          element={<SecurityTradePage />}
                        />
                      ) : (
                        <Route
                          path="*"
                          element={<Navigate to="/login" replace />}
                        />
                      )}
                    </Routes>
                    <ToastContainer />
                  </main>
                </div>
                {/* <AuthVerify /> */}
              </LocalizationProvider>
            </ThemeProvider>
          </ColorModeContext.Provider>
        </SkeletonTheme>
      </>
    </BrowserRouter>
  );
}

export default App;
